import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Navigate, useNavigate } from 'react-router-dom';
import NimblyRender from "./nimbly_wrapper";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { ProjectProvider } from './contexts/ProjectContext';
import { UserProvider } from './contexts/UserContext';
import { HighlightedPolesProvider } from './contexts/HighlightedPolesContext';
import { QueryResultsProvider } from './contexts/QueryResultsContext';
import { ReportTabProvider } from './contexts/ReportTabContext';
import { EnvironmentConfigProvider } from './contexts/EnvironmentConfigContext';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
// import NimblyHeader from "./global_components/header";
// import NimblyNavbar from "./global_components/navbar";
// import TopNavBar from "./global_components/top_nav";
// import BudgetPage from "./reliability/budget_page";
// import UserPage from "./reliability/user_page";

// import AnalyticalPage from "./reliability/analytical_page";
// import ReportPage from "./reliability/report_page";
// import OverviewMainPage from "./reliability/overview_page";
// import ExpandedPoleConditionsPage from "./reliability/pole_cond_expnded";
// import ExpandedInpectionItemsPage from "./reliability/inspection_itms_expnded";
// import ExpandedInspectionAddersPage from "./reliability/inspection_adders_expnded";

//global
import NimblyLoginPage from "./pages/global/login";
import MapPage from "./pages/global/map_page";

//cs
import CSDashoboard from "./pages/construction_services/cs_dashboard";
import ReliabilityDashboard from "./pages/reliability_services/rs_dashboard";
import CSReportPage from "./pages/construction_services/cs_report_page";
import CSUserPage from "./pages/construction_services/cs_user_page";

//temp 
import ReliabilityReportPage from "./pages/reliability_services/rs_report_page";

import { MapWrapper, DashboardWrapper, ReportWrapper, UserWrapper } from "./pages/global/wrappers";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const router = createBrowserRouter([
    {
        path: "/login",
        element: <NimblyLoginPage />,
        // errorElement: <ErrorPage />,
    },
    {
        path: "*",
        element: <NimblyLoginPage />,
        // errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <NimblyRender />,
        // errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to="/map" replace />
            },
            {
                path: "/map",
                //element: <OverviewMainPage />,
                element: <MapWrapper />,
            },
            {
                path: "/overview",
                //element: <OverviewMainPage />,
                element: <DashboardWrapper />,
            },
            // {
            //   path: "/budget",
            //   element: <BudgetPage />,
            // },
            {
                path: "/user",
                //element: <UserPage />,
                element: <UserWrapper />,

            },
            // {
            //   path: "/analytical",
            //   element: <AnalyticalPage />,
            // },
            {
                path: "/report",
                //element: <ReportPage />,
                element: <ReportWrapper />,
            },
        ],
    },

]);
root.render(

    <Auth0Provider
        domain="drg-pulse.us.auth0.com"
        //domain="dev-f3txc-mp.us.auth0.com"
        // localhost
        clientId="6O6qS6lEt5vi9YIZahnphvXKvlUrWWNu"

        //dev
        //clientId="Fb7ViFN8gx9z9Hb5fG5qqJhG4aK9Hlne"
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
    //onRedirectCallback={onRedirectCallback}
    >
        <EnvironmentConfigProvider>
            <ReportTabProvider>
                <QueryResultsProvider>
                    <HighlightedPolesProvider>
                        <ProjectProvider>
                            <UserProvider>
                                <RouterProvider router={router} />
                            </UserProvider>
                        </ProjectProvider>
                    </HighlightedPolesProvider>
                </QueryResultsProvider>
            </ReportTabProvider>
        </EnvironmentConfigProvider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
