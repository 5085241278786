import React, { useState, useEffect } from "react";
import { Button as KenButton } from "@progress/kendo-react-buttons";
import "../../styles/login.css"
import connexLogo from "../../icons/login_logo.png"
import login1 from "../../assets/images/login-1.png"
import login2 from "../../assets/images/login-2.png"
import login3 from "../../assets/images/login-3.png"
import login4 from "../../assets/images/login-4.png"
import logo from "../../assets/images/logo_blue.png"
import LoginButton from '../../global_components/loginbutton';
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList, } from "@progress/kendo-react-dropdowns";
import {
    Form,
    Field,
    FormElement,
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
    Error,
    Hint,
    FloatingLabel,
} from "@progress/kendo-react-labels";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useEnvironmentConfigContext } from '../../contexts/EnvironmentConfigContext';



const NimblyLoginPage = ({ onPageChange }) => {
    const [visible, setVisible] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [firstName, setfirstName] = React.useState("");
    const [lastName, setlastName] = React.useState();
    const [Email, setEmail] = React.useState();
    const [Project, setProject] = React.useState();
    const [Role, setRole] = React.useState();
    const [password, setPassword] = React.useState();
    const [segment, setSegment] = React.useState();
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [success, setSuccess] = React.useState(true);
    const [selectedSegment, setSelectedSegment] = React.useState();
    const { environmentConfig } = useEnvironmentConfigContext();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Poles/GetProjectAliases', {
                    headers: { 'frontendenvironmentconfig': environmentConfig, },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                //console.log("Fetched projects:", data); 
                var listTemp = [];
                data.map(item => listTemp.includes(item.Segment) ? null : listTemp.push(item.Segment));
                setSegment(listTemp);
            } catch (error) {
                console.error("Error fetching project aliases", error);
            }
        };
        fetchProjects();
    }, []);

    // pwd validator
    function goodPwd(string) {
        const
            upper = /[A-Z]/.test(string),
            lower = /[a-z]/.test(string);
        const leng = (string !== null && string !== undefined) ? (string.length >= 8 && string.length <= 16) : null;
        const number = /\d+/g.test(string);
        return upper && lower && leng && number;
    }
    const passwordValidator = (value) => goodPwd(value) ? "" : "At least 8 characters, must contain a combination of uppercase letters, lowercase letter and numbers.";
    const PasswordInput = (fieldRenderProps) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <div className="k-form-field-wrap">
                <Input {...others} labelClassName={"k-form-label"}/* onChange={(e)=>test1(e)}*/ />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
        );
    };

    // pwd confirmation validator
    function confirmPwd(string, valueGetter) {
        const confirmpwd = string == valueGetter("password") ? true : false
        return confirmpwd;
    }
    const passwordConfirmValidator = (value, valueGetter) => confirmPwd(value, valueGetter) ? "" : "Passwords do not match.";
    const PasswordConfirmInput = (fieldRenderProps) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <div className="k-form-field-wrap">
                <Input {...others} labelClassName={"k-form-label"} />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
                {fieldRenderProps.test}
            </div>
        );
    };

    // email validator
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = (value) => emailRegex.test(value) ? "" : "Please enter a valid email.";
    const EmailInput = (fieldRenderProps) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <div className="k-form-field-wrap">
                <Input {...others} labelClassName={"k-form-label"} validityStyles={false} />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
        );
    };

    const toggleDialog = () => {
        setVisible(!visible);
    };

    const test1 = (e) => {
        //console.log(e.target.value);
        //setConfirmPassword(e.target.value);
    };

    const toggleRegisterDialog = () => {
        setVisible(!visible);
        //setRegistered(!registered);

    };
    const onRegister = () => {
        RegisterUserData();
        setVisible(!visible);
    };

    const RegisterUserData = (dataItem) => {
        //setLoading(true);
        //console.log(dataItem);
        fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/Secrets/GetNimblyWebAPIKey', {
            method: 'GET',
            headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*" },
            //mode: 'no-cors',
        })
            .then((res) => {
                return res.text();;
            })
            .then((data) => {
                //console.log(data);
                fetch(process.env.REACT_APP_WEB_API_URL + '/api-common/poles/RegisterAuth0Users', {
                    method: 'POST',
                    headers: { 'frontendenvironmentconfig': environmentConfig, 'Content-Type': 'application/json', 'ApiKey': data, },
                    //mode: 'no-cors',
                    body: JSON.stringify({
                        firstName: dataItem.firstName,
                        lastName: dataItem.lastName,
                        email: dataItem.email,
                        password: dataItem.password,
                        business_segment: selectedSegment
                    })
                })
                    .then((res) => {
                        return res;;
                    })
                    .then((data) => {
                        //setLoading(false);
                        //setRegistered(!registered);
                        setSuccess(true);
                    });
            });
    }

    const handleBackBtnClick = (pageName) => {
        onPageChange(pageName);
    };

    const handleInputChange = (event, type) => {
        if (type == "First") {
            setfirstName(event.value)
        }
        else if (type == "Last") {
            setlastName(event.value)
        }
        else if (type == "Email") {
            setEmail(event.value)
        }
        else if (type == "Password") {
            setPassword(event.value)
        }
    };

    const handleSubmit = (dataItem) => {
        setVisible(!visible);
        RegisterUserData(dataItem);
    };

    const handleChange = (event) => {
        setSelectedSegment(event.target.value)
    };
    const position = {
        topCenter: {
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
        }
    };

    return (
        <>
            <div className="nimbly-login-background">
                <img src={login1} alt="" className="nimbly-login-img-1" />
                <img src={login2} alt="" className="nimbly-login-img-2" />
                <img src={login3} alt="" className="nimbly-login-img-3" />
                <img src={login4} alt="" className="nimbly-login-img-4" />
                <div className="nimbly-login-container">
                    <img src={logo} alt="" className="nimbly-login-logo" />
                    {/* <img src={connexLogo} alt="" /> */}
                    {/* <KenButton className="nimbly-login-btn" onClick={() => handleBackBtnClick("overview")}>Sign In</KenButton> */}
                    <LoginButton />
                    <p>Trouble Logging In?  / <a href="#" onClick={toggleDialog} id="open-window">Register Account</a></p>
                </div>
                {visible && (
                    <Window className="pulse-registration-window" title={"Account"} onClose={toggleDialog} initialHeight={480} initialWidth={420}>

                        {!registered ? <div>
                            <div className="pulse-user-window-header pulse-user-register-window-header">Create New Account</div>
                            <div>
                                <Form
                                    onSubmit={handleSubmit}
                                    render={(formRenderProps) => (
                                        <FormElement
                                            style={{
                                                maxWidth: 650,
                                            }}
                                        >
                                            <fieldset className={"k-form-fieldset"}>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        First name
                                              </Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"firstName"}
                                                            component={Input}
                                                            required={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        Last name
                                              </Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"lastName"}
                                                            component={Input}
                                                            required={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>

                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        Business Segment
                                              </Label>

                                                    <DropDownList
                                                        name={"segment"}
                                                        validityStyles={false}
                                                        data={segment}
                                                        required={true}
                                                        validationMessage={"Please select a business segment."}
                                                        labelClassName={"k-form-label"}
                                                        onChange={handleChange}
                                                    />
                                                </FieldWrapper>

                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        Email
                                              </Label>
                                                    <Field
                                                        name={"email"}
                                                        type={"email"}
                                                        component={EmailInput}
                                                        validator={emailValidator}
                                                        required={true}
                                                    />
                                                </FieldWrapper>

                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        Password
                                              </Label>
                                                    <Field
                                                        name={"password"}
                                                        type={"password"}
                                                        component={PasswordInput}
                                                        required={true}
                                                        validator={passwordValidator}
                                                    />
                                                </FieldWrapper>

                                                <FieldWrapper>
                                                    <Label className="k-form-label">
                                                        Confirm Password
                                              </Label>
                                                    <Field
                                                        name={"passwordconfrim"}
                                                        type={"password"}
                                                        component={PasswordConfirmInput}
                                                        required={true}
                                                        validator={passwordConfirmValidator}
                                                    />
                                                </FieldWrapper>

                                            </fieldset>
                                            <div className="k-form-buttons">
                                                <Button className="pulse-register-botton" disabled={!formRenderProps.allowSubmit} themeColor={"primary"}>Register</Button>
                                                <Button type="button" onClick={toggleDialog}>
                                                    Cancel
                                           </Button>
                                            </div>
                                        </FormElement>
                                    )}
                                />
                            </div>
                        </div> : <div>
                                <li><span class="k-icon k-font-icon k-i-check-outline"></span> .k-i-check-outline<br /> .k-i-checkmark-outline</li>
                                <Button type="button" onClick={toggleRegisterDialog}>
                                    Close
                             </Button>
                            </div>
                        }
                    </Window>
                )}
            </div>
        </>
    );
};

export default NimblyLoginPage;