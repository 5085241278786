import React, { createContext, useContext, useState, useEffect } from 'react';

const EnvironmentConfigContext = createContext();

export const EnvironmentConfigProvider = ({ children }) => {
    const [environmentConfig, setEnvironmentConfig] = useState("prod");

    return (
        <EnvironmentConfigContext.Provider value={{ environmentConfig, setEnvironmentConfig }}>
            {children}
        </EnvironmentConfigContext.Provider>
    );
};

export const useEnvironmentConfigContext = () => useContext(EnvironmentConfigContext);

